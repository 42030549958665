<template>
  <el-dialog
      title="账套基本信息"
      :visible.sync="isShow"
      width="440px"
      :append-to-body='$store.state.common.appendToBody'
      :destroyOnClose='$store.state.common.destroyOnClose'
      :close-on-click-modal='$store.state.common.closeOnClickModal'
    >
    <div class="content" style="max-height: 400px;overflow: auto;">
      <el-form label-position="right"  ref="JZForm" :model="accountInfo" label-width="150px">
        <el-form-item label="公司名称：" prop="companyName" required>
          {{accountInfo.companyName}}
        </el-form-item>
        <el-form-item label="公司规模：" prop="companySize" required>
          {{accountInfo.companySize}}
        </el-form-item>
        <el-form-item label="公司类型：" prop="companyType" required>
          {{accountInfo.companyType==='firm'?'厂商':accountInfo.companyType==='service'?'服务商':accountInfo.companyType==='hospitals'?'医院':accountInfo.companyType==='traders'?'贸易商':""}}
        </el-form-item>
        <el-form-item label="账套申请人：" prop="ledgerApplicantName" required>
          {{accountInfo.ledgerApplicantName}}
        </el-form-item>
        <el-form-item label="申请人电话：" prop="ledgerApplicantPhone" required>
          {{accountInfo.ledgerApplicantPhone}}
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer " style="text-align:right;" >
        <el-button @click="isShow=false" type='default' size="mini">取 消</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: 'ViewAccount',
  methods: {
    initPage () {
      this.isShow = true;
    },
  },
  data () {
    return {
      isShow: false,
      accountInfo: {
        companyName: '',
        companySize: '',
        companyType: '',
        ledgerApplicantName: '',
        ledgerApplicantPhone: '',
      },
    };
  },
};
</script>
<style scoped lang='scss'>
/* @import url(); 引入css类 */
/deep/.el-form-item--mini.el-form-item, /deep/ .el-form-item--small.el-form-item {
    margin-bottom: 0;
}
</style>
