<!--
 * @Date: 2021-09-28 14:01:29
 * @Author: Jeason
 * @LastEditors: Jeason
 * @LastEditTime: 2021-09-28 14:01:51
 * @Description: 账套信息
 * @FilePath: \sass_web\maintenance_expert\src\views\SystemSettings\account\index.vue
-->
<template>
  <div class="JZContainer">
    <div class="content_block">
      <query-form label-width="100px" @search="handleSearch" @reset="handleReset">
        <el-form-item label="订单号：">
          <el-input v-model="code" placeholder="请输入订单号"></el-input>
        </el-form-item>
        <el-form-item label="商品名称：">
          <el-input v-model="productName" placeholder="请输入商品名称"></el-input>
        </el-form-item>
        <el-form-item label="支付状态：">
          <el-select v-model="payStatus" @change="handleSearch">
            <el-option v-for="i in statusList" :key="i.value" :label="i.name" :value="i.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="开票状态：">
          <el-select v-model="invoiceStatus" @change="handleSearch">
            <el-option v-for="i in invoiceStatusList" :key="i.value" :label="i.name" :value="i.value"></el-option>
          </el-select>
        </el-form-item>
      </query-form>
    </div>
    <content-block title="订单列表">
      <div class="btnsContainer">
        <el-button
          :disabled="!selectedLists.length"
          icon="el-icon-plus"
          size="mini"
          type="primary"
          @click="handleBatchInvoicing"
          >批量开票申请</el-button
        >
        <!-- <div>
          <el-tag
            @click="handleChangeStatus('', '')"
            :effect="!invoiceStatus && !payStatus ? 'dark' : 'plain'"
            >全部</el-tag
          >
          <el-tag
            @click="handleChangeStatus('1', 'payStatus')"
            :type="payStatus === '1' ? 'primary' : 'warning'"
            :effect="payStatus === '1' ? 'dark' : 'plain'"
            >待付款</el-tag
          >
          <el-tag
            @click="handleChangeStatus('1', 'payStatus')"
            :type="payStatus === '1' ? 'primary' : 'warning'"
            :effect="payStatus === '1' ? 'dark' : 'plain'"
            >已取消</el-tag
          >
          <el-tag
            @click="handleChangeStatus('4', 'payStatus')"
            :type="payStatus === '4' ? 'primary' : 'warning'"
            :effect="payStatus === '4' ? 'dark' : 'plain'"
            >回单审核中</el-tag
          >
          <el-tag
            @click="handleChangeStatus('5', 'payStatus')"
            :type="payStatus === '5' ? 'primary' : 'warning'"
            :effect="payStatus === '5' ? 'dark' : 'plain'"
            >审核不通过</el-tag
          >
          <el-tag
            @click="handleChangeStatus('2', 'payStatus')"
            :type="payStatus === '2' ? 'primary' : 'warning'"
            :effect="payStatus === '2' ? 'dark' : 'plain'"
            >已付款</el-tag
          >
          <el-tag
            @click="handleChangeStatus('0', 'invoiceStatus')"
            :type="invoiceStatus === '0' ? 'primary' : 'danger'"
            :effect="invoiceStatus === '0' ? 'dark' : 'plain'"
            >未申请开票</el-tag
          > -->
          <!-- <el-tag
            @click="handleChangeStatus('1', 'invoiceStatus')"
            :type="invoiceStatus === '1' ? 'primary' : 'danger'"
            :effect="invoiceStatus === '1' ? 'dark' : 'plain'"
            >已申请</el-tag
          > -->
          <!-- <el-tag
            @click="handleChangeStatus('2', 'invoiceStatus')"
            type=""
            :effect="invoiceStatus === '2' ? 'dark' : 'plain'"
            >开票中</el-tag
          >
          <el-tag
            @click="handleChangeStatus('5', 'invoiceStatus')"
            :type="invoiceStatus === '5' ? 'primary' : 'success'"
            :effect="invoiceStatus === '5' ? 'dark' : 'plain'"
            >开票失败</el-tag
          >
          <el-tag
            @click="handleChangeStatus('3', 'invoiceStatus')"
            :type="invoiceStatus === '3' ? 'primary' : 'success'"
            :effect="invoiceStatus === '3' ? 'dark' : 'plain'"
            >开票完成</el-tag
          >
        </div> -->
      </div>
      <TableWithPagination
        :tableData="orderList"
        :checkbox-config="checkboxConfig"
        :columnList="orderColumnList"
        :pagination.sync="orderPagination"
        @current-change="getOrderList"
        @sort-change="getOrderList"
        @selection-change="handleSelectionChange"
      ></TableWithPagination>
    </content-block>
    <InvoicingInfo
      ref="invoicingInfo"
      @handleSearchOrder="handleSearchOrder"
    ></InvoicingInfo>
    <ViewAccount ref="viewAccount"></ViewAccount>
    <el-dialog :title="title" :visible.sync="dialogVisible" width="600px">
      <div v-if="isRevoke" class="dialog_container">
        <el-descriptions
          v-if="fileLists.length"
          :labelStyle="centerStyle"
          :contentStyle="centerStyle"
          :column="2"
          border
        >
          <el-descriptions-item>
            <template slot="label">
              {{ fileLists[0].attachName }}
            </template>
            <div style="width: 100px">
              <el-link type="primary" @click.native="handleView">查看</el-link>
              <el-link
                type="primary"
                style="margin-left: 1em"
                @click.native="handleDownload"
                >下载</el-link
              >
            </div>
          </el-descriptions-item>
        </el-descriptions>
        <span v-else> 暂无回单 </span>
        <div slot="footer" style="text-align: right; margin-top: 1em">
          <el-button @click="handleRevoke" :disabled="!fileLists.length"
            >撤销</el-button
          >
          <el-button type="primary" @click="dialogVisible = false"
            >关闭</el-button
          >
        </div>
      </div>
      <div v-else class="dialog_container">
        <div style="margin: 1em 0">驳回原因： {{ approveResult }}</div>
        <b style="margin-bottom: 0.5em; display: block">*上传回单：</b>
        <Upload
          v-model="fileLists"
          label="回单"
          type="single"
          @success="handleFileLists"
          @remove="handleRemove"
          accept="image/png,image/jpeg,image/jpg,application/pdf"
          :maxSize="5"
          size="origin"
          :limit="1"
        />
        <div slot="footer" style="text-align: right; margin-top: 1em">
          <el-button @click="dialogVisible = false">取消</el-button>
          <el-button type="primary" @click="handleUpload">提交</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getPayOrderList,
  revokePayVoucher,
  uploadPayVoucher,
  getPayOrderDetail,
} from '@/api/payment';
import InfoMixin from '@/mixins/infoMixin.js';
import SystemSetting from '@/api/systemSetting';
import InvoicingInfo from './components/invoicingInfo.vue';
import ViewAccount from './components/viewAccount.vue';
import FileSaver from 'file-saver';
import Upload from '@/components/common/upload';
export default {
  name: 'Account',
  components: {
    InvoicingInfo,
    ViewAccount,
    Upload,
  },
  mixins: [InfoMixin],
  methods: {
    handleSearch () {
      this.pagination.currentPage = 1;
      this.getOrderList();
    },

    handleReset () {
      this.invoiceStatus = '';
      this.payStatus = '';
      this.code = '';
      this.productName = '';
      this.pagination.currentPage = 1;
      this.getOrderList();
    },

    getTargetLists () {
      let currentParams = this.getCurrentParams();
      SystemSetting.getAccountLists(currentParams).then(
        this.getTargetListsSuccessFun,
      );
    },
    getCurrentParams () {
      return {
        pageNum: this.pagination.currentPage,
        pageSize: this.pagination.pageSize,
        orderByProperty: {
          orderByProperty: 'createTime',
          orderByType: 'DESC',
        },
      };
    },
    handleDetail (info) {
      SystemSetting.getDetailAccount({ id: info.id }).then(
        this.getDetailAccountSuccessFun,
      );
    },
    getDetailAccountSuccessFun (jsonData) {
      if (jsonData.heads.code === 200) {
        this.$refs.viewAccount.accountInfo = jsonData.body;
        this.$refs.viewAccount.isShow = true;
      } else {
        this.$JZShowAlert(jsonData.heads.message, 'error');
      }
    },
    handleSearchOrder () {
      this.orderPagination.currentPage = 1;
      this.getOrderList();
    },
    getOrderList () {
      let invoiceStatus = this.invoiceStatus;
      invoiceStatus === '3' && (invoiceStatus = '3,4');
      let currentParams = {
        pageNum: this.orderPagination.currentPage,
        pageSize: this.orderPagination.pageSize,
        invoiceStatusList: invoiceStatus ? invoiceStatus.split(',') : [],
        payStatusList: this.payStatus ? [this.payStatus] : [],
        code: this.code,
        productName: this.productName,
      };
      getPayOrderList(currentParams).then((res) => {
        this.orderList = res.body.list;
        this.orderPagination.total = res.body.total;
      });
    },
    handleRevoke () {
      this.$confirm(
        '撤销后将删除已上传的附件，之后可通过支付按钮重新上传或修改其他支付方式，确定吗？',
        '撤销',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        },
      ).then(() => {
        revokePayVoucher({ id: this.handleId }).then(() => {
          this.getOrderList();
          this.$message({
            type: 'success',
            message: '撤销成功!',
          });
        });

        this.dialogVisible = false;
      });
    },
    getDetail (id) {
      this.fileLists = [];
      this.approveResult = '';
      getPayOrderDetail(id).then((res) => {
        this.approveResult = res.body.approveResult || '';

        if (res.body.fileUrl) {
          this.fileLists.push({
            attachName: res.body.fileName || '--',
            url: res.body.fileUrl,
            name: res.body.fileName || '--',
          });
        }
      });
    },
    handleActionClick (info, status) {
      this.handleId = info.id;

      if (status === 'edit') {
        let defaultCheckedId;
        let defaultCheckedName;
        let payNameType;
        if (info.buyerId === this.tenantId) {
          defaultCheckedId = info.objId;
          defaultCheckedName = info.objName;
        } else {
          defaultCheckedId = info.serviceProviderId;
          defaultCheckedName = info.buyerName;
          payNameType = 'service';
        }
        this.$eventBus.$emit('openPaymentDialogManually', {
          defaultCheckedId,
          defaultCheckedName,
          timeStamp: Date.now(),
          payNameType,
        });
      } else if (status === 'apply') {
        this.$refs.invoicingInfo.targetLists = [info];
        this.$refs.invoicingInfo.status = 1;
        this.$refs.invoicingInfo.initPage();
      } else if (status === 'detail') {
        this.$refs.invoicingInfo.currentInvoiceId = info.invoiceApplyId;
        this.$refs.invoicingInfo.status = 3;
        this.$refs.invoicingInfo.initPage();
      } else if (status === 'check') {
        this.isRevoke = true;
        this.title = '查看回单';
        this.dialogVisible = true;
        this.getDetail(this.handleId);
      } else if (status === 'revoke') {
        this.$confirm(
          '撤销后将删除已上传的附件，之后可通过支付按钮重新上传或修改其他支付方式，确定吗？',
          '撤销',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          },
        ).then(() => {
          revokePayVoucher({ id: info.id }).then(() => {
            this.getOrderList();
            this.$message({
              type: 'success',
              message: '撤销成功!',
            });
          });
        });
      } else if (status === 'update') {
        this.getDetail(this.handleId);
        this.isRevoke = false;
        this.title = '修改回单';
        this.dialogVisible = true;
      }
    },
    handleSelectionChange (selectedLists) {
      this.selectedLists = selectedLists || [];
    },
    handleBatchInvoicing () {
      this.$refs.invoicingInfo.status = 1;
      this.$refs.invoicingInfo.targetLists = this.selectedLists;
      this.$refs.invoicingInfo.initPage();
    },
    handleChangeStatus (status, key) {
      if (key === 'payStatus') {
        this.payStatus = status;
        this.invoiceStatus = '';
      } else if (key === 'invoiceStatus') {
        this.invoiceStatus = status;
        this.payStatus = '';
      } else {
        this.invoiceStatus = '';
        this.payStatus = '';
      }
      this.getOrderList();
    },
    handleFileLists (file) {
      this.fileLists = [];
      this.fileLists.push(file);
    },
    handleRemove () {
      this.fileLists = [];
    },
    async handleUpload () {
      const params = {
        id: this.handleId,
        fileName: this.fileLists[0].attachName,
        fileUrl: this.fileLists[0].url,
      };

      await uploadPayVoucher(params);

      this.$message.success('已成功上传银行回单，请等待审核！');
      this.dialogVisible = false;
    },
    // 查看
    handleView () {
      window.open(this.fileLists[0].url);
    },
    // 下载
    handleDownload () {
      var oReq = new XMLHttpRequest();
      var URLToPDF = this.fileLists[0].url;
      oReq.open('GET', URLToPDF, true);
      oReq.responseType = 'blob';
      oReq.onload = () => {
        var file = new Blob([oReq.response], { type: 'application/pdf' });
        FileSaver.saveAs(file, this.fileLists[0].attachName);
      };
      oReq.send();
    },
  },
  data () {
    const tenantId = JSON.parse(localStorage.userInfo)?.tenantId;
    return {
      tenantId,
      approveResult: '',
      dialogVisible: false,
      isRevoke: false,
      handleId: '',
      productName: '',
      code: '',
      query: {
        orderId: null,
        name: '',
        invoiceStatus: null,
        paymentStatus: null,
      },
      statusList: [
        {
          name: '全部',
          value: '',
        },
        {
          name: '待付款',
          value: '1',
        },
        {
          name: '回单审核中',
          value: '4',
        },
        {
          name: '审核不通过',
          value: '5',
        },
        {
          name: '已付款',
          value: '2',
        },
        {
          name: '已取消',
          value: '6',
        },
      ],
      invoiceStatusList: [
        {
          name: '全部',
          value: '',
        },
        {
          name: '未申请开票',
          value: '0',
        },
        {
          name: '开票中',
          value: '2',
        },
        {
          name: '已开票',
          value: '3',
        },
        {
          name: '开票失败',
          value: '5',
        },
      ],
      centerStyle: {
        textAlign: 'center',
        width: '600px',
      },
      fileLists: [],
      title: '修改回单',
      payStatus: '',
      invoiceStatus: '',
      targetLists: [],
      orderList: [],
      orderPagination: {
        total: 0,
        currentPage: 1,
        pageSize: 10,
      },
      columnList: [
        {
          tooltip: true,
          label: '序号',
          minWidth: '110px',
          prop: '',
          type: 'index',
        },
        {
          tooltip: true,
          label: '账套名称',
          minWidth: '150px',
          prop: 'ledgerName',
          render: (h, { row }) => {
            let actions = [];
            let queryStatus
              = (this.$store.state.Auth
                && this.$store.state.Auth.actionPermissionMap[
                  'system:ledger:view'
                ])
              || false;
            if (queryStatus) {
              actions.push(
                h(
                  'span',
                  {
                    domProps: {
                      className: 'table-action',
                    },
                    on: {
                      click: this.handleDetail.bind(this, row),
                    },
                  },
                  row.ledgerName,
                ),
              );
              return h('div', actions);
            } else {
              return h('div', row.ledgerName);
            }
          },
        },
        {
          tooltip: true,
          label: '账套类型',
          minWidth: '150px',
          prop: 'ledgerType',
          format: (val) => {
            return val === 'MAIN_LEDGER' ? '主账套' : '--';
          },
        },
        {
          tooltip: true,
          label: '到期时间',
          minWidth: '110px',
          prop: 'expiryTime',
          format: this.$options.filters['date-format'],
        },
      ],
      checkboxConfig: {
        reserve: true,
        checkMethod: ({ row }) => {
          if (row.payStatus === '2' && row.invoiceStatus === '0') {
            return true;
          } else {
            return false;
          }
        },
      },
      selectedLists: [],
      orderColumnList: [
        {
          tooltip: true,
          label: '全部',
          minWidth: '100px',
          align: 'center',
          width: '',
          fixed: 'left',
          type: 'checkbox',
          prop: 'id',
        },
        {
          tooltip: true,
          label: '订单编号',
          minWidth: '150px',
          prop: 'code',
        },
        {
          tooltip: true,
          label: '商品名称',
          minWidth: '150px',
          prop: 'productName',
        },
        {
          tooltip: true,
          label: '医院名称',
          minWidth: '160px',
          prop: 'objName',
        },
        {
          tooltip: true,
          label: '服务商名称',
          minWidth: '160px',
          prop: 'buyerName',
        },
        {
          tooltip: true,
          label: '渠道名称',
          minWidth: '110px',
          prop: 'channelName',
        },
        {
          tooltip: true,
          label: '订单金额',
          minWidth: '110px',
          prop: 'totalAmount',
          format: (val) => {
            return parseFloat(val).toFixed(2);
          },
        },
        {
          tooltip: true,
          label: '支付时间',
          minWidth: '150px',
          prop: 'payTime',
          format: this.$options.filters['date-format'],
        },
        {
          tooltip: true,
          label: '到期时间',
          minWidth: '150px',
          prop: 'expiryTime',
          format: this.$options.filters['date-format'],
        },
        {
          tooltip: true,
          label: '支付方式',
          minWidth: '110px',
          prop: 'payMethodDesc',
        },
        {
          tooltip: true,
          label: '支付状态',
          minWidth: '110px',
          fixed: 'right',
          prop: 'payStatusDesc',
        },
        {
          tooltip: true,
          label: '开票状态',
          minWidth: '110px',
          fixed: 'right',
          prop: 'invoiceStatusDesc',
        },
        {
          tooltip: true,
          label: '支付主体',
          minWidth: '110px',
          prop: 'payName',
        },
        {
          tooltip: true,
          label: '操作',
          fixed: 'right',
          minWidth: '140px',
          render: (h, { row }) => {
            let actions = [];
            if (row.payStatus === '1') {
              actions.push(
                <span
                  class="cursor-pointer text-primary ml-1 table-action"
                  onClick={this.handleActionClick.bind(this, row, 'edit')}
                >
                  支付
                </span>,
              );
            } else if (row.payStatus === '2' && row.preparePayId === tenantId) {
              if (row.invoiceStatus === '0' && tenantId === row.payId) {
                actions.push(
                  <span
                    class="cursor-pointer text-primary ml-1 table-action"
                    onClick={this.handleActionClick.bind(this, row, 'apply')}
                  >
                    开票申请
                  </span>,
                );
              } else if (['1', '2', '3', '4', '5'].includes(row.invoiceStatus)) {
                actions.push(
                  <span
                    class="cursor-pointer text-primary ml-1 table-action"
                    onClick={this.handleActionClick.bind(this, row, 'detail')}
                  >
                    查看开票申请
                  </span>,
                );
              }
            } else if (row.payStatus === '4' && row.preparePayId === tenantId && row.payMethod === '3') {
              // 4->审核中
              actions.push(
                <span
                  class="cursor-pointer text-primary ml-1 table-action"
                  onClick={this.handleActionClick.bind(this, row, 'check')}
                >
                  查看回单
                </span>,
              );
              actions.push(
                <span
                  class="cursor-pointer text-primary ml-1 table-action"
                  onClick={this.handleActionClick.bind(this, row, 'revoke')}
                >
                  撤销
                </span>,
              );
            } else if (row.payStatus === '5' && row.preparePayId === tenantId && row.payMethod === '3') {
              // 5->审核不通过
              actions.push(
                <span
                  class="cursor-pointer text-primary ml-1 table-action"
                  onClick={this.handleActionClick.bind(this, row, 'update')}
                >
                  修改回单
                </span>,
              );
              actions.push(
                <span
                  class="cursor-pointer text-primary ml-1 table-action"
                  onClick={this.handleActionClick.bind(this, row, 'edit')}
                >
                  重新支付
                </span>,
              );
            }
            return <div>{...actions}</div>;
          },
        },
      ],
    };
  },
  created () {
    this.$eventBus.$on('systemSettingAccountList', this.getOrderList);
    let currentStatus = this.$route.query.status;
    if (currentStatus) {
      if (['1', '2'].includes(currentStatus)) {
        this.payStatus = currentStatus;
      } else if (['3', '2'].includes(currentStatus)) {
        this.invoiceStatus = currentStatus;
      }
    }
    this.getOrderList();
  },
  beforeDestroy () {
    this.$eventBus.$off('systemSettingAccountList', this.getOrderList);
  },
};
</script>
<style scoped lang='scss'>
/* @import url(); 引入css类 */
.btnsContainer {
  display: flex;
  margin-bottom: 10px;
  .el-button {
    padding: 0 10px;
    height: 32px;
    line-height: 32px;
  }
  div {
    flex: 1;
    margin-left: 20px;
    padding-top: 7px;
    .el-tag {
      margin-right: 10px;
      cursor: pointer;
    }
  }
}

.dialog_container {
  width: 500px;
  margin: 0 auto 10px;
}
</style>
