<template>
  <el-dialog
      :title="currentTitle"
      :visible.sync="isShow"
      width="70%"
      :append-to-body='$store.state.common.appendToBody'
      :destroyOnClose='$store.state.common.destroyOnClose'
      :close-on-click-modal='$store.state.common.closeOnClickModal'
    >
    <div class="content content_block" style="max-height: 500px;overflow: auto;">
      <ContentBlock title="申请信息："  >
        <el-form :model='JZInfo' label-width='125px' :class="{'noPadding': status === 3}" ref="JZInfo" :rules='JZInfoRules' inline label-position="right">
          <el-row >
            <el-col :span="8">
              <el-form-item label="发票抬头：" prop="buyerName" >
                <span v-if="isViewing">{{JZInfo.buyerName}}</span>
                <el-input placeholder="请输入发票抬头"  v-model.trim="JZInfo.buyerName" v-else maxlength='100' show-word-limit clearable></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="税号：" prop="buyerTaxNumber" >
                <span v-if="isViewing">{{JZInfo.buyerTaxNumber}}</span>
                <el-input placeholder="请输入税号"  v-model.trim="JZInfo.buyerTaxNumber" v-else maxlength='18' show-word-limit clearable></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="开票总额：" prop="totalAmount" >
                <el-input placeholder="请输入开票总额" disabled v-model.trim="JZInfo.totalAmount" v-if="!isViewing" ></el-input>
                <span v-else>{{JZInfo.totalAmount}}</span>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="发票类型：" prop="type" >
                <el-select v-model="JZInfo.type" :disabled='isViewing' v-if="!isViewing" placeholder="请选择发票类型" @change="handleInvoicingTypeChange" clearable>
                  <el-option
                    v-for="mod in invoicingTypeLists"
                    :key="mod.id"
                    :label="mod.name"
                    :value="mod.id">
                  </el-option>
                </el-select>
                <span v-else>{{JZInfo.typeDesc}}</span>
              </el-form-item>
            </el-col>
            <!-- <el-col :span="8">
              <el-form-item label="开户行及账号：" prop="buyerBankAccount" >
                <el-input placeholder="请输入开户行及账号" v-if="!isViewing" clearable :readonly="isViewing" maxlength='100' show-word-limit v-model.trim="JZInfo.buyerBankAccount"  ></el-input>
                <span v-else>{{JZInfo.buyerBankAccount}}</span>
              </el-form-item>
            </el-col> -->
            <el-col :span="8">
              <el-form-item label="开户行：" prop="buyerBank" >
                <el-input placeholder="请输入开户行" v-if="!isViewing" clearable :readonly="isViewing" maxlength='100' show-word-limit v-model.trim="JZInfo.buyerBank"  ></el-input>
                <span v-else>{{JZInfo.buyerBank}}</span>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="开户行账号：" prop="buyerAccount" >
                <el-input placeholder="请输入开户行账号" v-if="!isViewing" clearable :readonly="isViewing" maxlength='100' show-word-limit v-model.trim="JZInfo.buyerAccount"  ></el-input>
                <span v-else>{{JZInfo.buyerAccount}}</span>
              </el-form-item>
            </el-col>
            <!-- <el-col :span="8">
              <el-form-item label="地址、电话：" prop="buyerAddressMobile" >
                <el-input placeholder="请输入地址、电话" v-if="!isViewing" clearable :readonly="isViewing"  maxlength='100' show-word-limit v-model.trim="JZInfo.buyerAddressMobile"  ></el-input>
                <span v-else>{{JZInfo.buyerAddressMobile}}</span>
              </el-form-item>
            </el-col> -->
            <el-col :span="8">
              <el-form-item label="地址：" prop="buyerAddress" >
                <el-input placeholder="请输入地址" v-if="!isViewing" clearable :readonly="isViewing"  maxlength='100' show-word-limit v-model.trim="JZInfo.buyerAddress"  ></el-input>
                <span v-else>{{JZInfo.buyerAddress}}</span>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="电话：" prop="buyerMobile" >
                <el-input placeholder="请输入电话" v-if="!isViewing" clearable :readonly="isViewing"  maxlength='100' show-word-limit v-model.trim="JZInfo.buyerMobile"  ></el-input>
                <span v-else>{{JZInfo.buyerMobile}}</span>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="收票联系人：" prop="receiveName" >
                <el-input placeholder="请输入收票联系人" clearable v-if="!isViewing" :readonly="isViewing"  maxlength='20' show-word-limit v-model.trim="JZInfo.receiveName"  ></el-input>
                <span v-else>{{JZInfo.receiveName}}</span>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="联系电话：" prop="receivePhone" >
                <el-input placeholder="请输入联系电话" :readonly="isViewing" v-if="!isViewing" v-model.trim="JZInfo.receivePhone" maxlength='11' show-word-limit  clearable></el-input>
                <span v-else>{{JZInfo.receivePhone}}</span>
              </el-form-item>
            </el-col>
            <el-col :span="8" v-if="status === 3">
              <el-form-item label="申请时间：" prop="createTime" >
                <el-date-picker disabled type="datetime" v-if="!isViewing" placeholder="请选择申请时间" value-format="timestamp"  v-model.trim="JZInfo.createTime"></el-date-picker>
                <span v-else>{{JZInfo.createTime?$options.filters['date-format'](JZInfo.createTime):'--'}}</span>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row v-if="isElectronic">
            <el-col :span="16">
              <el-form-item label="电子邮件：" prop="receiveMail" >
                <el-input placeholder="请输入电子邮件" v-if="!isViewing" :readonly="isViewing" v-model.trim="JZInfo.receiveMail" maxlength='200' show-word-limit  clearable></el-input>
                <span v-else>{{JZInfo.receiveMail || '--'}}</span>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row v-else>
            <!-- <el-col :span="16">
              <el-form-item label="邮寄地址：" prop="receiveAddress" >
                <el-input placeholder="请输入邮寄地址" v-if="!isViewing" :readonly="isViewing" v-model.trim="JZInfo.receiveAddress" maxlength='200' show-word-limit  clearable></el-input>
                <span v-else>{{JZInfo.receiveAddress}}</span>
              </el-form-item>
            </el-col> -->
            <el-col :span="8">
              <el-form-item label="所在地区：" prop="receiveProvinceId">
                <area-cascader v-if="!isViewing"
                  :provinceId.sync="JZInfo.receiveProvinceId"
                  :provinceName.sync="JZInfo.receiveProvinceName"
                  :cityId.sync="JZInfo.receiveCityId"
                  :cityName.sync="JZInfo.receiveCityName"
                  :areaId.sync="JZInfo.receiveAreaId"
                  :areaName.sync="JZInfo.receiveAreaName"
                />
                <span v-else>{{JZInfo.receiveProvinceName}}{{JZInfo.receiveCityName}}{{JZInfo.receiveAreaName}}</span>
              </el-form-item>
              <!-- <el-form-item label="所在地区：" prop="receiveAddress" >
                <el-input placeholder="请输入邮寄地址" v-if="!isViewing" :readonly="isViewing" v-model.trim="JZInfo.receiveAddress" maxlength='200' show-word-limit  clearable></el-input>
                <span v-else>{{JZInfo.receiveAddress}}</span>
              </el-form-item> -->
            </el-col>
            <el-col :span="8">
              <el-form-item label="详细地址：" prop="receiveAddress" >
                <el-input placeholder="请输入详细地址" v-if="!isViewing" :readonly="isViewing" v-model.trim="JZInfo.receiveAddress" maxlength='200' show-word-limit  clearable></el-input>
                <span v-else>{{JZInfo.receiveAddress}}</span>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </ContentBlock>
      <ContentBlock title="流程进度：" v-if="status === 3" >
        <el-steps :active="stepActive" align-center finish-status="success" >
          <!-- <el-step title="已申请"></el-step>
          <el-step :title="stepActive===1?'待财务确认':'财务已确认'"></el-step>
          <el-step title="开票中"></el-step>
          <el-step v-if="stepActive === 3" :status="(stepActive===3&&invoiceStatus==7)?'error':'success'" :title="(stepActive===3&&invoiceStatus==7)?'开票失败':'开票完成'" :description="(stepActive===3&&invoiceStatus==7)?('原因:'+ this.JZInfo.rejectReason):''"></el-step>
          <el-step v-else title="开票完成"></el-step>
          <el-step :title="stepActive===4?'待寄出':'已寄出'" v-if="['1','2'].includes(this.JZInfo.type)"></el-step> -->
          <el-step title="申请开票"></el-step>
          <el-step title="开票中"></el-step>
          <el-step v-if="stepActive === 2" :status="(stepActive===2&&invoiceStatus==7)?'error':'success'" :title="(stepActive===2&&invoiceStatus==7)?'开票失败':'开票完成'" :description="(stepActive===2&&invoiceStatus==7)?('原因:'+ this.JZInfo.rejectReason):''"></el-step>
          <el-step v-else title="开票完成"></el-step>
        </el-steps>
      </ContentBlock>
      <!-- &&[4, 7].includes(stepActive) -->
      <ContentBlock title="开票信息：" v-if="status === 3">
        <el-form  label-width='110px'  inline label-position="right" :class="{'noPadding': status === 3}">
          <el-row>
            <el-col :span='8'>
              <el-form-item label="发票代码："  >
                {{JZInformation.invoiceCode || '--'}}
              </el-form-item>
            </el-col>
            <el-col :span='8'>
              <el-form-item label="发票号码："  required>
                {{JZInformation.invoiceNumber || '--'}}
              </el-form-item>
            </el-col>
            <el-col :span='8'>
              <el-form-item label="开票日期："  required>
                {{JZInformation.drawTime || '--'}}
              </el-form-item>
            </el-col>
            <el-col :span='8'>
              <el-form-item label="开票人：" >
                {{JZInformation.drawUserName || '--'}}
              </el-form-item>
            </el-col>
            <!--  && stepActive === 4 -->
            <el-col :span='16' v-if="isElectronic">
              <el-form-item label="发票附件："  required>
                {{JZInformation.fileName || '--'}}
                <el-link type="primary" :underline="false" class="ML5" v-if="JZInformation.fileName" @click.native="handleView">查看</el-link>
                <el-link type="primary" :underline="false" class="ML5" v-if="JZInformation.fileName" @click.native="handleDownload">下载</el-link>
              </el-form-item>
            </el-col>
            <el-col :span='8' v-if="!isElectronic">
              <el-form-item label="快递公司："  required>
                {{JZInformation.logisticsCompanyName || '--'}}
              </el-form-item>
            </el-col>
            <el-col :span='8' v-if="!isElectronic">
              <el-form-item label="物流单号："  required>
                {{JZInformation.logisticsCode || '--'}}
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </ContentBlock>
      <ContentBlock title="产品明细：">
        <div slot="title" class="content_title">
          <h4>产品明细：</h4>
          <div class="right" v-if='status===3'>
            订单数量：{{targetLists.length}}
            <div @click="showAll = !showAll" class="show-more blue">{{showAll?'收起':'展开'}}<i class="icon el-icon-arrow-up" v-if="showAll"></i> <i class="icon el-icon-arrow-down" v-else></i></div>
          </div>
        </div>
        <TableWithPagination
          v-if='showAll'
          :tableData="targetLists"
          :columnList="columnList"
           maxHeight='250px'
        ></TableWithPagination>
      </ContentBlock>
    </div>
    <span slot="footer" class="dialog-footer " style="text-align:right;" >
      <el-button @click="isShow=false" type='default' plain size="mini" v-if="[1, 2].includes(status)">取 消</el-button>
      <el-button @click="handleSubmit"  type='primary' size="mini" v-if="[1, 2].includes(status)">提交申请</el-button>
      <el-button @click="editInvoicingInfo" type='default' plain size="mini" v-if="status===3&&[0].includes(stepActive)">修改申请</el-button>
      <el-button @click="cancellationEditInvoicingInfo" type='default' plain size="mini" v-if="status===3&&[0].includes(stepActive)">撤销申请</el-button>
      <el-button @click="editInvoicingInfo" type='default' plain size="mini" v-if="status===3&&invoiceStatus==7">重新申请</el-button>
      <el-button @click="isShow=false" type='default' plain size="mini" v-if="status===3">关闭</el-button>
    </span>
  </el-dialog>
</template>

<script>
import TableWithPagination, { TablePaginationMixin } from '@/components/common/TableWithPagination';
import {detailInvoice, updateInvoice, addInvoice, deleteInvoice, getEnableInvoiceTypeList} from '@/api/invoice';
import FileSaver from 'file-saver';
import AreaCascader from '@/components/common/areaCascader';

export default {
  name: 'InvoicingInfo',
  components: {
    TableWithPagination,
    AreaCascader,
  },
  mixins: [TablePaginationMixin],
  methods: {
    getDetailInvoice () {
      detailInvoice({id: this.currentInvoiceId}).then((res) => {
        let currentInfo = res.body;
        this.JZInfo = {
          buyerName: currentInfo.buyerName,
          buyerTaxNumber: currentInfo.buyerTaxNumber,
          totalAmount: currentInfo.totalAmount,
          type: currentInfo.type,
          buyerBankAccount: currentInfo.buyerBankAccount,
          buyerAccount: currentInfo.buyerAccount,
          buyerBank: currentInfo.buyerBank,
          buyerAddressMobile: currentInfo.buyerAddressMobile,
          buyerAddress: currentInfo.buyerAddress,
          buyerMobile: currentInfo.buyerMobile,
          receiveName: currentInfo.receiveName,
          receivePhone: currentInfo.receivePhone,
          receiveAddress: currentInfo.receiveAddress,
          receiveProvinceName: currentInfo.receiveProvinceName,
          receiveCityName: currentInfo.receiveCityName,
          receiveAreaName: currentInfo.receiveAreaName,
          receiveAreaId: currentInfo.receiveAreaId,
          receiveCityId: currentInfo.receiveCityId,
          receiveProvinceId: currentInfo.receiveProvinceId,
          createTime: currentInfo.createTime,
          receiveMail: currentInfo.receiveMail,
          typeDesc: currentInfo.typeDesc,
          rejectReason: currentInfo.rejectReason,
        };
        this.JZInformation = {
          invoiceNumber: currentInfo.invoiceNumber,
          invoiceCode: currentInfo.invoiceCode,
          drawTime: currentInfo.drawTime ? this.$options.filters['date-format'](currentInfo.drawTime, 'YYYY-MM-DD') : '--',
          drawUserName: currentInfo.drawUserName,
          logisticsCompanyName: currentInfo.logisticsCompanyName,
          logisticsCode: currentInfo.logisticsCode,
          fileName: currentInfo.fileName || '',
          fileUrl: currentInfo.fileUrl || '',
        };
        if (currentInfo.status === '1') {
          this.stepActive = 0;
        } else if (currentInfo.status === '2') {
          // this.stepActive = currentInfo.status * 1;
          this.stepActive = 1;
        } else {
          this.stepActive = currentInfo.status * 1 + 1;
        }
        if (['4', '5'].includes(currentInfo.status)) {
          this.stepActive = 2;
        }
        if (['7'].includes(currentInfo.status)) {
          this.stepActive = 2;
        }
        this.invoiceStatus = currentInfo.status;
        let itemList = currentInfo.itemList || [];
        itemList = itemList.map(item => {
          return {
            id: item.objId,
            code: item.objCode,
            objName: item.objName,
            channelName: item.channelName,
            totalAmount: item.amount,
            payTime: item.payTime,
            payMethodDesc: item.payMethodDesc,
          };
        });
        this.targetLists = itemList;
      });
    },
    handleSubmit () {
      this.$refs.JZInfo.validate(valid => {
        if (valid) {
          let currentParams = {
            ...this.JZInfo,
            orderIdList: this.targetLists.map(item => item.id),
            totalQuantity: this.targetLists.length,
          };
          if (this.status === 1) {
            addInvoice(currentParams).then(() => {
              this.$emit('handleSearchOrder');
              this.isShow = false;
            });
          } else if (this.status === 2) {
            currentParams['id'] = this.currentInvoiceId;
            updateInvoice(currentParams).then(() => {
              this.$emit('handleSearchOrder');
              this.isShow = false;
            });
          }
        }
      });
    },
    editInvoicingInfo () {
      this.status = 2;
      this.initPage();
    },
    cancellationEditInvoicingInfo () {
      let settings = {};
      Object.assign(settings, { // 合并对象
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        dangerouslyUseHTMLString: true, // 允许确认框内容为html格式
      });
      this.$confirm('<p>确定要撤销开票申请吗？</p>', settings).then(() => {
        deleteInvoice({idList: [this.currentInvoiceId]}).then(() => {
          this.$emit('handleSearchOrder');
          this.isShow = false;
        });
      });
    },
    handleInvoicingTypeChange () {
      let isElectronic = ['3', '4'].includes(this.JZInfo.type);
      if (isElectronic) {
        this.JZInfo.receiveAddress = '';
      } else {
        this.JZInfo.receiveMail = '';
      }
      this.clearValidate();
    },
    clearValidate () {
      this.$nextTick(() => {
        if (this.$refs.JZInfo) {
          this.$refs.JZInfo.clearValidate();
        }
      });
    },
    // 查看
    handleView () {
      window.open(this.JZInformation.fileUrl);
    },
    // 下载
    handleDownload () {
      var oReq = new XMLHttpRequest();
      var URLToPDF = this.JZInformation.fileUrl;
      oReq.open('GET', URLToPDF, true);
      oReq.responseType = 'blob';
      oReq.onload = () => {
        var file = new Blob([oReq.response], {type: 'application/pdf'});
        FileSaver.saveAs(file, this.JZInformation.fileName);
      };
      oReq.send();
    },
    initPage () {
      this.isShow = true;
      this.JZInfo = {
        buyerName: this.$store.state.User.currentUserInfo.tenantName,
        buyerTaxNumber: this.$store.state.User.currentUserInfo.certCode,
        totalAmount: '',
        type: '',
        typeDesc: '',
        buyerBankAccount: '',
        buyerBank: '',
        buyerAccount: '',
        buyerAddressMobile: '',
        buyerMobile: '',
        buyerAddress: '',
        receiveName: this.$store.state.User.currentUserInfo.name,
        receivePhone: this.$store.state.User.currentUserInfo.phoneNumber,
        receiveAddress: '',
        receiveProvinceId: '',
        receiveProvinceName: '',
        receiveCityId: '',
        receiveCityName: '',
        receiveAreaId: '',
        receiveAreaName: '',
        receiveMail: '',
        rejectReason: '',
      };
      if (this.status === 3) {
        this.showAll = false;
      } else {
        this.showAll = true;
      }
      this.isViewing = (this.status === 3);
      if ([3, 2].includes(this.status)) {
        this.getDetailInvoice();
      }
      this.clearValidate();
      getEnableInvoiceTypeList().then((res) => {
        this.invoicingTypeLists = res.body;
      });
    },
  },
  data () {
    return {
      status: 1, // [1,2,3] = ['新增','编辑','查看']
      isShow: false,
      isViewing: false,
      showAll: false,
      currentInvoiceId: '',
      stepActive: 0,
      invoiceStatus: 0,
      // form: {
      //   address: '',
      //   receiveAreaId: '',
      //   receiveAreaName: '',
      //   receiveCityId: '',
      //   receiveCityName: '',
      //   receiveProvinceId: '',
      //   receiveProvinceName: '',
      // },
      JZInfo: {
        buyerName: '',
        buyerTaxNumber: '',
        totalAmount: '',
        type: '',
        typeDesc: '',
        buyerBankAccount: '',
        buyerBank: '',
        buyerAccount: '',
        buyerAddressMobile: '',
        buyerMobile: '',
        buyerAddress: '',
        receiveName: '',
        receivePhone: '',
        receiveAddress: '',
        receiveAreaId: '',
        receiveAreaName: '',
        receiveCityId: '',
        receiveCityName: '',
        receiveProvinceId: '',
        receiveProvinceName: '',
        createTime: '',
        rejectReason: '',
      },
      JZInformation: {},
      orderLists: [],
      targetLists: [],
      columnList: [
        {
          tooltip: true,
          label: '订单编号',
          minWidth: '110px',
          prop: 'code',
        },
        {
          tooltip: true,
          label: '服务用户',
          minWidth: '160px',
          prop: 'objName',
        },
        {
          tooltip: true,
          label: '渠道名称',
          minWidth: '110px',
          prop: 'channelName',
        },
        {
          tooltip: true,
          label: '订单金额',
          minWidth: '110px',
          prop: 'totalAmount',
          format: val => {
            return parseFloat(val).toFixed(2);
          },
        },
        {
          tooltip: true,
          label: '支付时间',
          minWidth: '110px',
          prop: 'payTime',
          format: this.$options.filters['date-format'],
        },
        {
          tooltip: true,
          label: '支付方式',
          minWidth: '110px',
          prop: 'payMethodDesc',
        },
      ],
      invoicingTypeLists: [],
    };
  },
  computed: {
    currentTitle () {
      let status = this.status;
      return status === 1 ? '新增开票申请' : status === 2 ? '修改开票申请' : status === 3 ? '查看开票申请' : '';
    },
    isElectronic () {
      return ['3', '4'].includes(this.JZInfo.type);
    },
    JZInfoRules () {
      let isElectronic = ['3', '4'].includes(this.JZInfo.type);
      let currentInfoRules = {
        buyerName: [
          {
            required: true,
            message: '发票抬头必填！',
            trigger: ['blur', 'change'],
          },
        ],
        buyerTaxNumber: [
          {
            required: true,
            message: '税号必填！',
            trigger: ['blur', 'change'],
          },
          { pattern: /^[A-Z\d]{18}$/, message: '税号为18位数字或大写英文字母组合', trigger: 'blur'},
          {
            validator: (rule, value, callback) => {
              // 排除全字母的场景
              const reg = /^[A-Z]{18}$/g;
              if (reg.test(value)) {
                callback(new Error('税号为18位数字或大写英文字母组合'));
              }
              callback();
            },
            trigger: ['blur', 'change'],
          },
        ],
        totalAmount: [
          {
            required: true,
            message: '开票总额必填！',
            trigger: ['blur', 'change'],
          },
        ],
        type: [
          {
            required: true,
            message: '请选择发票类型',
            trigger: ['blur', 'change'],
          },
        ],
        // buyerBankAccount: [
        //   {
        //     required: ['2', '4'].includes(this.JZInfo.type),
        //     message: '开户行及账号必填！',
        //     trigger: ['blur', 'change'],
        //   },
        // ],
        buyerBank: [
          {
            required: ['2', '4'].includes(this.JZInfo.type),
            message: '开户行必填！',
            trigger: ['blur', 'change'],
          },
        ],
        buyerAccount: [
          {
            required: ['2', '4'].includes(this.JZInfo.type),
            message: '开户行账号必填！',
            trigger: ['blur', 'change'],
          },
        ],
        // buyerAddressMobile: [
        //   {
        //     required: ['2', '4'].includes(this.JZInfo.type),
        //     message: '地址、电话必填！',
        //     trigger: ['blur', 'change'],
        //   },
        // ],
        buyerAddress: [
          {
            required: ['2', '4'].includes(this.JZInfo.type),
            message: '地址必填！',
            trigger: ['blur', 'change'],
          },
        ],
        buyerMobile: [
          {
            required: ['2', '4'].includes(this.JZInfo.type),
            message: '电话必填！',
            trigger: ['blur', 'change'],
          },
        ],
        receiveName: [
          {
            required: true,
            message: '收票联系人必填！',
            trigger: ['blur', 'change'],
          },
        ],
        receivePhone: [
          {
            required: true,
            message: '联系电话必填！',
            trigger: ['blur', 'change'],
          },
          // {
          //   pattern: /^1\d{10}$/,
          //   message: '请输入正确的手机号！',
          //   trigger: ['blur', 'change'],
          // },
        ],
        receiveAddress: [
          {
            required: !isElectronic,
            message: '请输入邮寄地址',
            trigger: ['blur', 'change'],
          },
        ],
        receiveProvinceId: [{ required: true, message: '请选择地址', trigger: 'blur' }],
        receiveMail: [
          {
            required: isElectronic,
            message: '请输入邮寄邮件',
            trigger: ['blur', 'change'],
          },
          {
            type: 'email',
            message: '邮寄邮件格式不正确',
            trigger: ['blur', 'change'],
          },
        ],
      };
      return currentInfoRules;
    },
  },
  watch: {
    targetLists (list) {
      let total = 0;
      if (list.length) {
        for (var i = 0; i < list.length; i++) {
          total += list[i].totalAmount;
        }
      }
      this.JZInfo.totalAmount = parseFloat(total).toFixed(2);
    },
  },
};
</script>
<style scoped lang='scss'>
/* @import url(); 引入css类 */
/deep/ .el-form-item--small.el-form-item{
  display: flex;
  .el-form-item__content{
    flex: 1;
    .el-date-editor.el-input{
      width: 100%;
    }
  }
}
/deep/ .content_title{
  display: flex;
  margin-bottom: 20px;
  &::before{
    width: 5px;
    height: 24px;
    content: '';
    background: #2577e3;
    margin-right: 10px;
  }
  h4{
    font-weight: normal;
    flex: 1;
  }
  .right{
    margin-right: 10px;
    display: flex;
    .show-more{
      flex: 1;
      margin-left: 10px;
      .icon{
        margin-left: 5px;
      }
    }
  }
}
/deep/ .blue{
  color: rgba(15, 137, 255, 0.7);
  cursor: pointer;
}
/deep/ .noPadding .el-form-item--small.el-form-item{
  margin-bottom: 0;
}
/deep/ .el-step__title.is-success, /deep/ .el-step__head.is-success{
  color: #409eff;
  border-color: #409eff;
  .el-step__line{
    background-color: #409eff;
  }
}
/deep/ .el-step__title.is-process, /deep/ .el-step__head.is-process{
  .el-step__icon.is-text{
    border-radius: 50%;
    border: 2px solid;
    border-color: #409EFF;
    color: #FFF;
    background-color: #409EFF;
  }
}
/deep/ .ML5{
  margin-left: 10px;
}
// /deep/ .el-row .el-col{
//   // height: 52px;
// }
// /deep/ .noPadding  .el-row .el-col{
//   // height: 32px;
// }
</style>
